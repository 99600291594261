<template>
  <v-dialog v-model="deleteMedia.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Archive Image</v-card-title>
      <v-card-text
        >Are you sure you wish to archive
        {{ deleteMedia.images.name }}?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
        <v-btn
          color="success"
          depressed
          :loading="deleteMedia.loading"
          @click="saveDelete"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      deleteMedia: {
        dialog: false,
        images: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(media) {
      this.deleteMedia.images = media;
      this.deleteMedia.dialog = true;
    },

    resetDelete() {
      this.deleteMedia.dialog = false;
      this.deleteMedia.images = {};
      this.deleteMedia.loading = false;
    },

    saveDelete() {
      this.deleteMedia.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/deleteMedia", {
          appId: this.$route.params.id,
          mediaId: this.deleteMedia.images.id,
          propertyId: this.$route.params.propertyId,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteMedia.loading = false;
        });
    },
  },
};
</script>
